import { configureStore } from "@reduxjs/toolkit";
import userSlice from './slices/userSlice'
import userApi from "./api/userApi";
const store = configureStore({
  reducer: {
    user: userSlice,
    [userApi.reducerPath]: userApi.reducer,


    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),

  devTools:false,
  
    
 
 
});

export default store;
