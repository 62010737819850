import React from "react";
import { Helmet } from "react-helmet";

const ReactHelmet = ({ title, description, image, creator }) => {
  return (
    // <Helmet>
    //   <title>{title}</title>
    //   <meta name="description" content={description} />
    //   <link rel="icon" href={image} />
    //   <meta property="og:title" content={title} />
    //   <meta property="og:description" content={description} />
    //   <meta property="og:image" content={image} />
    //   <meta property="og:site_name" content="myfortfolio" />
    //   <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
    //   <meta property="og:type" content="portfolio" />
    //   <meta name="twitter:title" content={title} />
    //   <meta name="twitter:description" content={description} />
    //   <meta name="twitter:image" content={image} />
    //   <meta name="twitter:card" content="summary_large_image" />
    //   <meta name="twitter:site" content={creator} />
    //   <meta name="twitter:creator" content={creator} />
    //   <meta name="author" content={creator} />
    //   <meta
    //     name="keywords"
    //     content="portfolio, developer, web developer, web designer, web developer portfolio, web designer portfolio, web developer portfolio template, profiles, resume, cv, personal website, personal portfolio"
    //   />
    //   <meta name="robots" content="index, follow" />
    //   <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    //   <meta name="language" content="English" />
    //   <meta name="revisit-after" content="7 days" />
    //   <meta property="fb:admins" content="princecodemon" />
    // </Helmet>

    <Helmet>
      <link rel="icon" href={image} />
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {creator && <meta property="og:creator" content={creator} />}
      {creator && <meta name="author" content={creator} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="MyFolioHub" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:article:author" content={creator} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:data1" content={creator} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
      <meta property="og:site_name" content="MyFolioHub" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:article:author" content={creator} />
      <meta name="author" content={creator} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default ReactHelmet;
