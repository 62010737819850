import React from 'react'
import { useGetAllFoliosQuery } from '../store/api/userApi';
import ReactHelmet from '../Components/ReactHelmet';

const Faq = () => {
  const { data } = useGetAllFoliosQuery();
  return (
    <section class=" bg-gray-900">
      <ReactHelmet title={'Frequently Asked Questions : MyFolioHub'} description={'Get answers to frequently asked questions about MyFolioHub like how to create an account, how to get started, and more. '} />
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 class="mb-8 text-4xl tracking-tight font-extrabold  text-white">
          Frequently asked questions
        </h2>
        <div class="grid pt-8 text-left border-t  md:gap-16 border-gray-700 md:grid-cols-2">
          <div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                How do I get started with MyFolioHub?
              </h3>
              <p class=" text-gray-400">
                To get started with MyFolioHub, you can either register a new
                account or login if you already have one. Simply visit the
                website and click on the "Register" or "Login" button to begin
                the process.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                What information do I need to provide when creating my
                portfolio?
              </h3>
              <p class=" text-gray-400">
                When creating your portfolio, you'll be asked to provide various
                personal details such as your name, address, mobile number,
                skills, social media links, and a bio that describes yourself
                and your professional background. These details help customize
                your portfolio and showcase your unique profile.
              </p>
            </div>

            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Can I include my educational details and project information in
                my portfolio?
              </h3>
              <p class=" text-gray-400">
                Absolutely! MyFolioHub allows you to showcase your educational
                background and project details. You can provide information such
                as the name of the educational board, graduation year,
                grade/class, and any notable projects you've worked on.
                Additionally, you can include project images, titles,
                descriptions, and links to demonstrate your skills and
                accomplishments.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                How can I access my portfolio after creating it?
              </h3>
              <p class=" text-gray-400">
                Once you've successfully filled in your personal and
                professional details and chosen a username, your portfolio
                website will be created. You can access it by visiting
                "myfoliohub.com/folio/username" where "username" is the chosen
                username you provided during the setup process.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Can the images be updated automatically on the database when
                changing or uploading them on the edit page?
              </h3>
              <p class=" text-gray-400">
                Yes, on the edit page of MyFolioHub, when you change or upload
                an image, it will be automatically updated in the database
                without the need to click an additional "Update" button. The
                system is designed to detect any changes made to the image and
                save them in real-time, ensuring that your portfolio reflects
                the updated image without any manual intervention.
              </p>
            </div>
          </div>
          <div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Can I share my portfolio on social media platforms?
              </h3>
              <p class=" text-gray-400">
                Yes, MyFolioHub provides easy sharing options for your
                portfolio. You can share your portfolio directly on popular
                platforms like WhatsApp, Facebook, and LinkedIn by clicking on
                share button then on respective social media icons.
                Additionally, you can copy the link to your portfolio and share
                it through other channels.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                How long does it take for search engines to crawl my portfolio
                website?
              </h3>
              <p class=" text-gray-400">
                Once your portfolio is created, it usually takes between 2 to 5
                days for search engines to crawl and index your website. This
                means your portfolio has the potential to appear in search
                engine results when people search for your name or related
                keywords.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                How do I edit my portfolio page?
              </h3>
              <p class=" text-gray-400">
                To edit your portfolio page, log in to your account and navigate
                to the edit page section. In the edit page, you can choose from
                various templates to customize the design of your portfolio. You
                can also upload your resume, add services such as web or app
                development, and make other modifications as desired.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Is there a delete button to remove my portfolio?
              </h3>
              <p class=" text-gray-400">
                Currently, there is no delete button available on the website.
                If you wish to remove your portfolio, please reach out to the
                support team of MyFolioHub for assistance.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium  text-white">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5  text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Can I track the number of views on my portfolio?
              </h3>
              <p class=" text-gray-400">
                Yes, MyFolioHub provides a feature that allows you to track the
                number of views on your portfolio. By visiting your profile
                page, click on menu button on bottom right you can see the view
                count, which provides insights into the popularity and
                engagement of your portfolio.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-16 text-center">
          <h2 class="text-3xl font-bold  text-white">FAQ</h2>
          <p class="mt-4  text-gray-400">
            Still have questions?{" "}
            <a
              href="mailto:myfoliohub@gmail.com"
              target="blank"
              rel="noopener"
              class="text-indigo-600 hover:underline"
            >
              Contact us
            </a>
          </p>
        </div>
      </div>
      {data?.length > 0 &&
        data?.map((item) => {
          return (
            <a
              href={`/folio/${item.username}`}
              className="hidden"
              hidden
              aria-hidden
              aria-disabled
              aria-label="Folio"
              key={item.username}
              security='same-origin'
              draggable="false"
              click="true"
            >
              h
            </a>
          );
        })}
    </section>
  );
}

export default Faq