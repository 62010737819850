import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

// const profile = JSON.parse(localStorage.getItem("profile"))
// localStorage.setItem("profile", JSON.stringify(profile))

var personal, educations, projects;
if (typeof window !== "undefined") {
  // Perform localStorage action
  personal = JSON.parse(localStorage.getItem("personal"));
  educations = JSON.parse(localStorage.getItem("educations"));
  projects = JSON.parse(localStorage.getItem("projects"));
  // console.log("personal", personal);
}

function getToken() {
  const token = localStorage.getItem("myfortfolio");
  if (token) {
    const isExp = jwtDecode(token).exp * 1000;
    const now = new Date().getTime();
    if (now < isExp) {
      return token;
    }
    localStorage.removeItem("myfortfolio");
    return null;
  }
  return null;
}

function getUser() {
  const token = getToken()
  if (token) {
    const decode = jwtDecode(token);
    return decode._id;
  }
  return null;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: getUser(),
    profile: {
      personal: personal
        ? personal
        : {
            name: "",
            jobPosition: "",
            bio: "",
            address: "",
            mobile: "",
            skills: [],
            social: {
              facebook: "",
              twitter: "",
              linkedin: "",
              github: "",
              instagram: "",
            },
          },

      educations: educations
        ? educations
        : [
            {
              education: "",
              branch: "",
              board: "",
              yearOfPassing: new Date().getFullYear(),
              grade: 1,
            },
          ],
      experiences: [
        {
          company: "",
          jobPosition: "",
          from: "",
          to: "",
          current: false,
          description: "",
        },
      ],
      projects: projects
        ? projects
        : [
            {
              title: "",
              description: "",
              link: "",
              image: "",
              codeLink: "",
            },
          ],
    },
    step: 0,
    token: getToken(),
    isCreated: false,
  },
  reducers: {
    login: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("myfortfolio", action.payload);
      state.user = jwtDecode(action.payload)._id;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("myfortfolio");
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    // setErrors: (state, action) => {
    //   state.errors = action.payload;
    // },
    // setName: (state, action) => {
    //   state.name = action.payload;
    // },
    // setIsCreated: (state, action) => {
    //   state.isCreated = action.payload;
    // },
    // setProfileName: (state, action) => {
    //   state.profile.name = action.payload;
    // },
    setEducation: (state, action) => {
      console.log("action", action.payload);
      state.profile.educations = action.payload;
      localStorage.setItem("educations", JSON.stringify(action.payload));
    },
    setProjects: (state, action) => {
      state.profile.projects = action.payload;
      localStorage.setItem("projects", JSON.stringify(action.payload));
    },
    setPersonal: (state, action) => {
      console.log("action", action.payload);
      state.profile.personal = action.payload;
      localStorage.setItem("personal", JSON.stringify(action.payload));
    },
    clearProfile: (state, action) => {
      state.profile.personal = {}
      state.profile.educations = []
      state.profile.projects = [];
      localStorage.removeItem("educations");
      localStorage.removeItem("projects");
      localStorage.removeItem("personal");
    },
  },
});

export const {
  login,
  logout,
  setStep,
  setProfile,
  setErrors,
  setName,
  setToken,
  setIsCreated,
  setProfileName,
  setEducation,

  setProjects,
  setPersonal,
  clearProfile,
} = userSlice.actions;

export default userSlice.reducer;
