import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.token;
      headers.set("Secret-Key", `${process.env.REACT_APP_API_SECRET}`);
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: "/api/user/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "/api/user/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    verifyEmail: builder.query({
      query: (token) => ({
        url: `/api/user/verifyemail/${token}`,
        method: "GET",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/api/user/forgotpassword",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `/api/user/resetpassword/${body.token}`,
        method: "POST",
        body: {
          password: body.password,
        },
      }),
    }),
    adminProfile: builder.query({
      query: () => ({
        url: "/api/user/adminprofile",
        method: "GET",
        validateStatus:(response,status)=>{
        if(status ===400){
          return false
        }
        return true
        
        }
      }),
      providesTags: ["User"],
    }),
    createProfile: builder.mutation({
      query: (body) => ({
        url: "/api/user/createprofile",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    getUser: builder.query({
      query: (username) => ({
        url: `/api/user/profile/${username}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    editProfile: builder.mutation({
      query: (body) => ({
        url: "/api/user/editprofile",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updateProjects: builder.mutation({
      query: (body) => ({
        url: "/api/user/updateprojects",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    getAllFolios: builder.query({
      query: () => ({
        url: "/api/user/allFolios",
        method: "GET",
      }),
      providesTags: ["User"],
    })
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useVerifyEmailQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useAdminProfileQuery,
  useCheckUsernameMutation,
  useCreateProfileMutation,
  useEditProfileMutation,
  useUpdateProjectsMutation,
  useGetAllFoliosQuery
} = userApi;

export default userApi;
