import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Faq from "./pages/Faq";

const Home = lazy(() => import("./pages/Home"));
const NF = lazy(() => import("./pages/NF"));
const Navbar = lazy(() => import("./Components/Navbar"));
const Footer = lazy(() => import("./Components/Footer"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const VerifyEmail = lazy(() => import("./pages/auth/VerifyEmail"));
const CreateProfile = lazy(() => import("./pages/user/CreateProfile"));
const EditProfile = lazy(() => import("./pages/user/EditProfile"));
const Profile = lazy(() => import("./pages/user/Profile"));
const Folio = lazy(() => import("./pages/Folio"));
const Private = lazy(() => import("./routes/Private"));
const UserProtected = lazy(() => import("./routes/UserProtected"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));


function App() {
  return (
    <>
      <Navbar />
      {/* <Confeti/> */}
      <Suspense
        fallback={
          <div
            className="flex w-full items-center justify-center py-10 min-h-[91vh] bg-gray-900"
            style={{ height: "100vh" }}
          >
            <HashLoader color="#6366F1" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <UserProtected>
                <Home />
              </UserProtected>
            }
          />
          <Route path="/folio/:id" element={<Folio />} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="/auth">
            <Route
              path="login"
              element={
                <UserProtected>
                  <Login />
                </UserProtected>
              }
            />
            <Route
              path="register"
              element={
                <UserProtected>
                  <Register />
                </UserProtected>
              }
            />
            <Route
              path="forgotpassword"
              element={
                <UserProtected>
                  <ForgotPassword />
                </UserProtected>
              }
            />
            <Route
              path="resetpassword/:token"
              element={
                <UserProtected>
                  <ResetPassword />
                </UserProtected>
              }
            />
            <Route
              path="verifyemail/:token"
              element={
                <UserProtected>
                  <VerifyEmail />
                </UserProtected>
              }
            />
            <Route path="*" element={<NF />} />
          </Route>
          <Route path="/user">
            <Route
              path="createprofile"
              element={
                <Private>
                  <CreateProfile />
                </Private>
              }
            />
            <Route
              path="editprofile"
              element={
                <Private>
                  <EditProfile />
                </Private>
              }
            />
            <Route
              path="profile"
              element={
                <Private>
                  <Profile />
                </Private>
              }
            />
            <Route path="*" element={<NF />} />
          </Route>
          <Route path="*" element={<NF />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;


// const NF = lazy(() => "./pages/NF.js");
// const Navbar = lazy(() => "./Components/Navbar.js");
// const Footer = lazy(() => "./Components/Footer.js");
// const Login = lazy(() => "./pages/auth/Login.js");
// const Register = lazy(() => "./pages/auth/Register.js");
// const ForgotPassword = lazy(() => "./pages/auth/ForgotPassword.js");
// const VerifyEmail = lazy(() => "./pages/auth/VerifyEmail.js");
// const CreateProfile = lazy(() => "./pages/user/CreateProfile.js");
// const EditProfile = lazy(() => "./pages/user/EditProfile.js");
// const Profile = lazy(() => "./pages/user/Profile.js");
// const Profile1 = lazy(() => "./pages/Profile.js");
// const Private = lazy(() => "./routes/Private.js");
// const UserProtected = lazy(() => "./routes/UserProtected.js");
// const ResetPassword = lazy(() => "./pages/auth/ResetPassword.js");

// import Home from './pages/Home'
// import NF from "./pages/NF";
// import Navbar from "./Components/Navbar";
// import Footer from "./Components/Footer";
// import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import VerifyEmail from "./pages/auth/VerifyEmail";
// import CreateProfile from "./pages/user/CreateProfile";
// import EditProfile from "./pages/user/EditProfile";
// import Profile from "./pages/user/Profile";
// import Profile1 from "./pages/Profile";
// import Private from "./routes/Private";
// import UserProtected from "./routes/UserProtected";
// import ResetPassword from "./pages/auth/ResetPassword";